import React from 'react'
import * as styles from "./scheme-cost.module.scss"
import Fancybox from "../../components/fancybox.js";
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"

export const SchemeCost = () => {
  const { ref, inView  } = useInView({
    triggerOnce: true,
  });
  return (
    <>
      <Fancybox>
        <a
          href="https://i-project.by/portfolio_newsite/cost/cost_schema_mobile.webp"
          data-fancybox="gallery"
          >
            <StaticImage className="cost_schema_mobile" src="../../images/cost/cost_schema_mobile.jpg" alt="Схема работы команды iproject с клиентом и нашими подрядчиками" />
        </a>
      </Fancybox>
      <div className={styles.cost_schema}>
        <div className={styles.cost_schema_block1}>
          <div className={styles.cost_schema__client}>
            <p className={styles.cost_schema__client_p}>Наш клиент</p>
          </div>
          <div className={styles.cost_schema__realize_lines_block}>
            <div className={styles.cost_schema__realize_line_bottom}></div>
          </div>
          <div className={styles.cost_schema__realize + " " + (inView? styles.active : " ")}>
            <p className={styles.cost_schema__realize_p}>Реализованный объект</p>
          </div>
        </div>
        <div className={styles.cost_schema__iproject}>
          <p className={styles.cost_schema__iproject_p}>Автор проекта</p>
          <div className={styles.cost_schema__iproject_item + " " + styles.cost_schema__iproject_item1}>
            <p className={styles.cost_schema__iproject_item_p}>Дизайн проект,<br />Архитектурный проект</p>
          </div>
          <div className={styles.cost_schema__iproject_item + " " + styles.cost_schema__iproject_item2}>
            <p className={styles.cost_schema__iproject_item_p}>Сводный проект</p>
          </div>
          <div className={styles.cost_schema__iproject_line_bottom}></div>
        </div>
        <div className={styles.cost_schema_contractors}>
          <div className={styles.cost_schema_contractor}>
            <p className={styles.cost_schema_contractor_title}>Смежные проектировщики</p>
            <div className={styles.cost_schema_contractor_items}>
              <ul className={styles.cost_schema_contractor_list}>
                <li className={styles.cost_schema_contractor_li}>Конструктивные решения (КР)</li>
                <li className={styles.cost_schema_contractor_li}>Отопление и&nbsp;вентиляция (ОВ)</li>
                <li className={styles.cost_schema_contractor_li}>Водопровод и&nbsp;канализация (ВК)</li>
              </ul>
              <ul className={styles.cost_schema_contractor_list}>
                <li className={styles.cost_schema_contractor_li}>Электрооборудование и&nbsp;автоматизация (ЭО + &laquo;Умный Дом&raquo;)</li>
                <li className={styles.cost_schema_contractor_li}>Инженерная геодезия (топосъемка)</li>
                <li className={styles.cost_schema_contractor_li}>Инженерная геология (бурение скважин, анализ грунта)</li>
              </ul>
            </div>
          </div>
          <div className={styles.cost_schema_contractor}>
            <p className={styles.cost_schema_contractor_title}>Поставщики товаров и&nbsp;услуг</p>
            <div className={styles.cost_schema_contractor_items}>
              <p className={styles.cost_schema_contractor_p}>Салоны и&nbsp;магазины по&nbsp;продаже: электрооборудования, сантехоборудования, строительных материалов, отделочных материалов, мебели, штор, предметов интерьера и&nbsp;т.п.</p>
            </div>
          </div>
          <div className={styles.cost_schema_contractor + " " + styles.cost_schema_contractor3}>
            <p className={styles.cost_schema_contractor_title}>Производители работ (Мебель)</p>
            <div className={styles.cost_schema_contractor_items}>
              <ul className={styles.cost_schema_contractor_list + " " + styles.cost_schema_contractor_list3}>
                <li className={styles.cost_schema_contractor_li}>Менеджеры</li>
                <li className={styles.cost_schema_contractor_li}>Проектировщики</li>
                <li className={styles.cost_schema_contractor_li}>Конструкторы</li>
                <li className={styles.cost_schema_contractor_li}>Монтажники</li>
                <li className={styles.cost_schema_contractor_li}>Логисты</li>
              </ul>
            </div>
          </div>
          <div ref={ref} className={styles.cost_schema_contractor + " " + styles.cost_schema_contractor4}>
            <p className={styles.cost_schema_contractor_title}>Производители работ (Строительство и&nbsp;ремонт)</p>
            <div className={styles.cost_schema_contractor_items}>
              <p className={styles.cost_schema_contractor_p}>Прорабы, кладочники, монолитчики, штукатуры, маляры, плиточники,сантехники, электрики, оконщики, жестянщики, кровельщики, благоустраители, геодезисты, геологи и&nbsp;т.&nbsp;п.</p>
            </div>
            <div className={styles.cost_schema_contractor4_line_bottom}></div>
          </div>
        </div>
      </div>
    </>
    
  )
}