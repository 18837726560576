import * as React from "react"
import { Accordion } from "./accordion"


export const Faq = () => {
  const config  = [
    {
      title:' Для чего нужен дизайн проект?',
      text:'<p>Словосочетание дизайн проект состоит из&nbsp;двух составляющих его слов/понятий&nbsp;— дизайн и&nbsp;проект. Дизайн интерьера нужен для того&nbsp;же, для чего в&nbsp;принципе нужен любой дизайн,&nbsp;— для удобства и&nbsp;красоты, а&nbsp;проект необходим, т.к. удобство нужно продумать, а&nbsp;красоту придумать. Для этого нужно понимать как работают законы эргономики, композиции и&nbsp;цвета, ориентироваться в&nbsp;планировочных нормах и&nbsp;конструктивных особенностях, инженерных сетях и&nbsp;коммуникациях, свойствах материалов и&nbsp;специфике самого разного оборудования.</p><p>В&nbsp;дизайн проект входят все документы, которые позволяют построить новый интерьер в&nbsp;вашей квартире. Это дает возможность рассчитать стоимость всех работ и&nbsp;предусмотреть все возможные проблемы на&nbsp;этапе проектирования.</p>'
    },    {
      title:' Сколько времени вы&nbsp;делаете дизайн-проект квартиры?',
      text:'<p>В&nbsp;среднем около 60&nbsp;дней.</p>'
    },    {
      title:' Будет&nbsp;ли учтен бюджет при разработке дизайн проекта?',
      text:'<p>Мы&nbsp;можем точно рассчитать все затраты на&nbsp;строительные работы и&nbsp;черновые материалы. Затраты&nbsp;же на&nbsp;чистовые материалы, мебель и&nbsp;оборудование удобнее подсчитывать в&nbsp;процессе комплектации, и&nbsp;эта услуга у&nbsp;нас совмещена с&nbsp;услугой авторского сопровождения.</p>'
    },    {
      title:' В&nbsp;каком стиле дизайна вы&nbsp;работаете?',
      text:'<p>Мы&nbsp;работаем во&nbsp;всех стилистических направлениях без ограничений.</p>'
    },    {
      title:' Как оплачивается дизайн-проект?',
      text:'<p>Дизайн-проект оплачивается в&nbsp;4&nbsp;этапа по&nbsp;25% от&nbsp;стоимости, наличными или переводом на&nbsp;расчетный счет.</p>'
    },    {
      title:' Сколько времени понадобится на&nbsp;ремонт квартиры?',
      text:'<p>От&nbsp;6-ти до&nbsp;12-ти месяцев. Важно также понимать, что речь идет о&nbsp;создании уникального интерьера по&nbsp;индивидуальному проекту, не&nbsp;путать с&nbsp;так называемым &laquo;евроремонтом&raquo;.</p>'
    },    {
      title:' В каких случаях нужна перепланировка?',
      text:'<p>Основная задача Застройщика, который продает вам квартиру,&nbsp;&mdash; извлечь из&nbsp;объекта максимальную прибыль, не&nbsp;нарушая существующих строительных норм. Типовые планировочные решения далеки от&nbsp;совершенства, пространство внутри квартиры распределено как минимум не&nbsp;оптимально, а&nbsp;иногда и&nbsp;попросту не&nbsp;рационально.</p>'
    },    {
      title:' Сколько планировочных концепций вы делаете?',
      text:'<p>Столько, сколько потребуется, пока не&nbsp;найдем идеальное решение. Мы&nbsp;знаем как нивелировать недостатки помещений, грамотно распределить пространство для работы и&nbsp;отдыха, выделив при этом достаточно места для хранения, хозяйственных и&nbsp;бытовых нужд. Разумная и&nbsp;креативная планировочная концепция&nbsp;&mdash; залог удобного и&nbsp;красивого интерьера.</p>'
    },
  ]
  return (
      <Accordion  config={config}/>
  )
}